
























interface Iconfig {
  limit?: number; // 图片张数 默认1张
  callback?: string; // 图片回显地址 多张逗号分开
  accept?: string; // 图片类型 默认image/jpeg,image/png,image/jpg
  fileName?: string; // 上传文件的字段名字,默认时file
  drag?: boolean; // 是否启用拖拽上传
  params?: any; // 上传时附带的额外参数 { id: 123 }
}
interface Idialog {
  show: boolean;
  image: string;
}
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FileUpload } from '@/http/api/FileApi';

@Component
export default class UploadImage extends Vue {
  @Prop() private config!: Iconfig;
  @Prop() private listClean!: boolean;
  private hideUploadEdit: boolean = false;
  private limit: number = 1;
  private callback: string = '';
  private accept: string = 'image/jpeg,image/png,image/jpg';
  private fileName: string = 'file';
  private drag: boolean = false;
  private params: any = null;
  private fileList: any[] = [];
  private imgArr: any[] = [];
  private imgDialog: Idialog = {
    show: false,
    image: '',
  };

  @Watch('config', {
    immediate: true,
    deep: true,
  })
  private wacthConfig(n: Iconfig, o: Iconfig) {
    if (n.limit !== this.limit && n.limit) {
      this.limit = n.limit;
    }
    if (n.accept) {
      this.accept = n.accept;
    }
    if (n.callback !== this.callback && n.callback) {
      this.callback = n.callback;
      const arr = n.callback.split(',');
      const newArr = arr.map((item) => ({ url: process.env.VUE_APP_IMG_URL + item, path: item }));
      console.log(newArr)
      this.imgArr = newArr;
      this.fileList = newArr;
      this.hideUploadEdit = this.fileList.length >= this.limit;
    }
  }
  private created() {
    if (this.config.callback) {
      this.limit = this.config.limit || 1
      const arr = this.config.callback.split(',')
      const newArr = arr.map(item => ({ url: process.env.VUE_APP_IMG_URL + item, path: item }))
      this.imgArr = newArr
      console.log(this.imgArr)
      this.fileList = newArr
      this.hideUploadEdit = this.fileList.length >= this.limit

    }
  }
  @Watch('imgArr')
  private watchImgArr(n: any, o: any) {
    const arr: any = [];
    for (const item of n) {
      arr.push(item.path);
    }
    this.$emit('UploadImageFun', arr.join(','));
  }
  @Watch('listClean')
  private WatchListCleann() {
    this.imgArr = [];
    this.fileList = [];
  }
  // 查看大图
  private handlePictureCardPreview(file: any) {
    this.imgDialog.image = file.url;
    this.imgDialog.show = true;
  }
  // 本地校验上传框框显示隐藏
  private handleChange(file: any, fileList: any) {
    this.fileList = fileList;
    this.hideUploadEdit = fileList.length >= this.limit;
  }
  // 删除文件
  private handleRemove(file: any, fileList: any) {
    for (let i = 0; i < this.imgArr.length; i++) {
      if (file.path === this.imgArr[i].path) {
        this.imgArr.splice(i, 1);
      }
    }
    this.fileList = fileList;
    this.hideUploadEdit = fileList.length >= this.limit;
  }
  // 上传文件
  private AdminUploadFileUp(f: any) {
    const formdata = new FormData();
    formdata.append('file', f.file);
    const ff = {
      timeout: 0,
    };
    FileUpload(formdata, ff).then((res: any) => {
      if (res) {
        this.fileList[this.fileList.length - 1].path = res.result.filesMap.file[0].path;
        this.imgArr.push(res.result.filesMap.file[0]);
      } else {
        this.fileList.splice(this.fileList.length - 1, 1);
        this.hideUploadEdit = this.fileList.length >= this.limit;
      }
    });
  }
}
